<template>
    <div class="news">
        <myhead :param="param" />
        <section>
            <div class="banneranews">
                <div style="height:280px; "></div>
                <div style="font-size: 44px;font-weight: bold;color: #ffffff;">新闻动态</div>
             </div>
        </section>
        <section style="margin-top: 90px;">
            <div class="body1400 htable" style="height: 400px;" v-if="rows[0]" >
                <div class="hcell" style="width: 600px;height: 400px;overflow: hidden;background-color:rgba(230,230,230,0.5);">
                    <img style="width: 600px;" :src="rows[0].tb_image"  :title="rows[0].tb_title" >
                </div>
                <div  class="hcell" style="width: 800px;height: 400px;background-color:#ededed;display: flex;">
                    <div style="margin-left: 50px;text-align: left;width: 680px;margin-top:70px;">
                        <div style="font-size: 20px;font-weight: bold;color: #d7000f;">
                            {{rows[0].tb_create_time |mmt('YYYY-MM-DD')}}
                        </div>
                        <div style="font-size: 20px;font-weight: bold;margin-top: 20px;">
                            <a class="mgd" @click="goto('/new',{id: rows[0].tb_id})" >{{rows[0].tb_title}}</a>
                        </div>
                        <div style="font-size: 16px;color: rgba(0,0,0,0.8);margin-top: 26px;line-height: 28px;height: 190px;overflow: hidden;">
                            <a class="mgd" @click="goto('/new',{id: rows[0].tb_id})" >{{rows[0].tb_summary}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="body1400" style="margin-top: 20px;" v-if="rows[1]">
                <div class="htable" style="margin-top: 70px" v-for="(n,i) of Math.ceil((rows.length-1)/3)" :key="'item'+rows[3*i+1].tb_id">
                    <div class="hcell newblock" v-if="rows[3*i+1]" >
                        <div class="newsimg">
                            <img class="mimg" :src="rows[3*i+1].tb_image"  :title="rows[3*i+1].tb_title">
                        </div>
                        <div class="newtitle" :title="rows[3*i+1].tb_title"><a class="mgd" @click="goto('/new',{id: rows[3*i+1].tb_id})" >{{rows[3*i+1].tb_title}}</a></div>
                        <div class="newnote"><a class="mgd" @click="goto('/new',{id: rows[3*i+1].tb_id})" >{{rows[3*i+1].tb_summary}}</a></div>
                        <div class="newdate">{{rows[3*i+1].tb_create_time |mmt('YYYY-MM-DD')}}</div>
                    </div>
                    <div class="hcell newblock newpleft" v-if="rows[3*i+2]">
                        <div class="newsimg">
                            <img class="mimg" :src="rows[3*i+2].tb_image"  :title="rows[3*i+2].tb_title">
                        </div>
                        <div class="newtitle" :title="rows[3*i+2].tb_title"><a class="mgd" @click="goto('/new',{id: rows[3*i+2].tb_id})" >{{rows[3*i+2].tb_title}}</a></div>
                        <div class="newnote"><a class="mgd" @click="goto('/new',{id: rows[3*i+2].tb_id})" >{{rows[3*i+2].tb_summary}}</a> </div>
                        <div class="newdate">{{rows[3*i+2].tb_create_time |mmt('YYYY-MM-DD')}}</div>
                    </div>
                    <div class="hcell newblock newpleft" v-else></div>
                    <div class="hcell newblock newpleft" v-if="rows[3*i+3]">
                        <div class="newsimg">
                            <img class="mimg" :src="rows[3*i+3].tb_image" :title="rows[3*i+3].tb_title" >
                        </div>
                        <div class="newtitle" :title="rows[3*i+3].tb_title"><a class="mgd" @click="goto('/new',{id: rows[3*i+3].tb_id})" >{{rows[3*i+3].tb_title}}</a></div>
                        <div class="newnote"><a class="mgd" @click="goto('/new',{id: rows[3*i+3].tb_id})" >{{rows[3*i+3].tb_summary}}</a> </div>
                        <div class="newdate">{{rows[3*i+3].tb_create_time |mmt('YYYY-MM-DD')}}</div>
                    </div>
                    <div class="hcell newblock newpleft" v-else></div>
                </div>
                <pages :page="param.page" :size="param.rows" :total="total" @go="goPage" style="margin-top: 50px;"></pages>
            </div>
        </section>
        <section style="margin-top: 80px;">

        </section>
    </div>
</template>

<script>
    import myhead  from  '../components/myhead.vue'
    import pages from '../components/pagesc.vue'
    import { listTopicBase } from "../hts/api.js"
    const param = {
        pageindex: 6,
        rows: 7,
        page: 1,
    }
    export default {
        name: "indexbody",

        components: {
            myhead,
            pages,
        },
        data() {
            return {
                tmp: require('../assets/ysp3.png'),
                page:1,
                param,
                total: 0,
                rows:[],
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            goPage (page) {
                this.param.page = page
                this.getList()
            },
            getList () {
                // console.log(this.id)
                listTopicBase({fbId: '19', page: this.param.page, rows: this.param.rows })
                    .then(resData => {
                        this.rows=resData.data.rows
                        this.total=resData.data.total
                        //alert(res.data.rows[0].tb_content)
                        //alert(data.rows[0].tb_content)
                    })
                    .catch()
            },
            goto(url,query) {
                this.$router.push({ path: url, query: query });
            },
        },
    }
</script>

<style lang="css" scoped>
    .news {
        width: 100%;
    }
    .banneranews{
        background-image: url('~assets/news.png');
        height: 550px;
    }
    .newblock{
        width: 450px;
        height: 500px;
        background-color: #f6f6f6;
        overflow: hidden;
    }
    .newsimg{
        height: 300px;
        background-color:rgba(230,230,230,0.5);
        overflow: hidden;
    }
    .mimg{
        width: 450px;
    }
    .newtitle{
        height: 25px;
        overflow: hidden;
        font-size: 20px;
        font-weight: bold;
        text-align: left;
        padding-left: 30px;
        padding-right: 30px;
        margin-top: 25px;
    }
    .newnote{
        font-size: 16px;
        color: rgba(0,0,0,0.8);
        text-align: left;
        padding-left: 30px;
        padding-right: 30px;
        line-height: 28px;
        margin-top: 15px;
        height:85px;
        overflow: hidden;
    }
    .newdate{
        font-size: 16px;
        color: rgba(0,0,0,0.6);
        text-align: left;
        padding-left: 30px;
        padding-right: 30px;
        margin-top: 10px;
    }
    .newpleft{
        margin-left: 25px
    }
    .mgd {
        cursor:pointer;
    }
    .mgd:hover {
        background-color: rgba(203,203,203,1);
        color: rgba(24,24,24,0.9);
        border-radius: 5px;
    }
</style>

